<template lang="pug">
    div
        header-component
        router-view

</template>
<script>
import HeaderComponent from '@/components/MainLayout/HeaderComponent'
export default {
    name: 'MainLayout', //nombre del componente
    components: {
        HeaderComponent
    },
    beforeMount() {
        //document.body.classList.add('alt-menu', 'sidebar-noneoverflow')
    },
    destroyed() {
        //document.body.classList.remove('hold-transition', 'sidebar-mini', 'layout-navbar-fixed')
    }
}
</script>